export default {
  computed: {
    list() {
      return this.$store.state.moduleMicrosoft.list;
    },
    isLoading() {
      return this.$store.state.moduleMicrosoft.isLoading;
    },
  },
  methods: {
    fetchList() {
      return this.$store.dispatch('moduleMicrosoft/fetchList');
    },
  },
};
